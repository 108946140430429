import React from "react";
import { Helmet } from "react-helmet";
import HeroSection from "../TopHero";

function Home() {
  return (
    <div>
      <Helmet>
        <title>Fastenova - Superior Fasteners, Nuts, Bolts, and Screws</title>
        <meta
          name="description"
          content="From wood screws, sheet metal screws, nuts, bolts to washers, we provide a comprehensive selection of fasteners at unbeatable prices. Experience the FasteNova difference today!"
        />
        <link rel="canonical" href="http://fastenova.com" />
      </Helmet>
      <HeroSection />
    </div>
  );
}

export default Home;
